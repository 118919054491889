import React from 'react';
import { HashLink } from 'react-router-hash-link';

import StepTitle from '../step-title/StepTitle';
import StepDescription from '../step-description/StepDescription';

import './StepSection.scss';

const StepSection = ( {id, step} ) => {
  return (
    <section id={id}>
      <StepTitle
        id={id}
        title={step.title}
      />

      <StepDescription
        id={id}
        desc={step.desc}
      />
      {id === 0 ?
        <HashLink 
          to='/sign'
          className="link"
        >
          <button className="account_btn">Create Account</button>
        </HashLink> :
        ""
      }
    </section>
  );
}

export default StepSection;