import React, {useEffect}  from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import SvgIcon from '../svg-icon/SvgIcon';

import './OrderFlowCard.scss';

import Request from '../../assets/request.png';
import Contract from '../../assets/contract.png';
import Shipping from '../../assets/shipping.png';
import Receive from '../../assets/receive.png';


const OrderFlowCard = ({ id, icon, title, desc }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const step = parseInt(id) + 1
  const duration = 1000 + parseInt(id)*600;

  const requestIcon = () => {
    return (
      <img src={Request} alt="request" />
    );
  }
  const contractIcon = () => {
    return (
      <img src={Contract} alt="purchase order" />
    );
  }
  const shippingIcon = () => {
    return (
      <img src={Shipping} alt="shipping" />
    );
  }
  const receiveIcon = () => {
    return (
      <img src={Receive} alt="receive" />
    );
  }

  const iconList = [requestIcon(), contractIcon(), shippingIcon(), receiveIcon()];
  
  return (
    <div 
      className="card"
      data-aos="fade-up"
      data-aos-duration={duration} 
      data-aos-once="true"
    >
      <span>{0 + step.toString()}</span>
      <div className="card__icon">
        {iconList[id]}
      </div>
      <h4>{title}</h4>
      <p className={`card__desc ${id === "0" ? 'request' : '' }`}>{desc}</p>
    </div>
  );
}

export default OrderFlowCard;