import React from 'react';

import ContactForm from '../contact-form/ContactForm';

import './Contact.scss';

const Contact = () => {
  return (
    <section className="contact">
      <ContactForm />
    </section>
  );
}

export default Contact;