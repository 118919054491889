import React from 'react';

import { benefits } from '../../data/benefits.json';
import Benefit from '../benefit/Benefit';


import Easy from '../../assets/easy.png';
import Fast from '../../assets/fast.png';

import './benefits.scss';

const Benefits = () => {
  return (
    <section className="benefits">
      <div className="benefits__container">
        <div className="benefit">
          <div className="benefit__imgBox">
            {/*{image}*/}
            <img src={Easy} alt="alt"/>
          </div>
          <div 
            className="benefit__content"
            data-aos="zoom-in-up"
            data-aos-duration="1000" 
            data-aos-once="true"
          >
            <h4>採購流程容易</h4>
            <p className="benefit__desc">來信詢問採購商品及報價，簽訂採購訂單，即安排出貨。流程簡易，提供中文服務，海外廠商英文溝通交給Uselec，客戶免煩惱。</p>
          </div>
        </div>
        <div className="benefit">
          <div className="benefit__imgBox">
            {/*{image}*/}
            <img src={Fast} alt="alt"/>
          </div>
          <div 
            className="benefit__content"
            data-aos="zoom-in-up"
            data-aos-duration="1000" 
            data-aos-once="true"
          >
            <h4>快速供貨</h4>
            <p className="benefit__desc">Uselec始終提供客戶最佳品質、準時交貨、最高成本效益的支援。我們專注於提供台灣電子工廠所需電子提供相關機器設備、零件、原料、化學品電子產品。</p>
          </div>
        </div>
      </div>
    </section>
        
  );
}

export default Benefits;